import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from 'gatsby';
import RichText from "prismic-reactjs/src/Component";

const Holder = styled.div`
  color: ${props => props.colour === 'purple' ? props.theme.colours.white : props.theme.colours.black};
  background-color: ${props => props.theme.colours[props.colour]};
  padding: 3rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 5rem 0;
  }
`;

const Intro = styled.div`
  width: 100%;
  max-width: ${props=> props.theme.typography.maxScreen}px;
  padding: 2rem 2rem;
  margin: 0 auto;
  p {
    max-width: 50rem;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  a {

  }
  li {
    border-top: 1px solid;
    &:last-child { border-bottom: 1px solid;  }
    &:hover {
      background-color: ${props => props.theme.colours.yellow};
    }

    a {
      margin: 0 auto;
      max-width: ${props=> props.theme.typography.maxScreen}px;
      padding: 1rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      align-items: start;
      text-decoration: none;
      transition: background-color 0.25s;
      @media( ${props => props.theme.breakpoints.md} ) { 
        grid-template-columns: 1fr 1fr;
      }
      @media( ${props => props.theme.breakpoints.lg} ) { 
        grid-template-columns: 2fr 1fr 3fr 1fr;
      }
      h3, small { 
        margin: 0;
      }
      small {
        @media( ${props => props.theme.breakpoints.lg} ) {
          grid-column: 3/4;
        }
      }
    }
  }
`;

const SlicePostContainer = styled.div`
  width: 100%;
`;

function SlicePosts({ slice, posts } ) {
  return (
    <Holder colour={slice.colour.toLowerCase()}>
      <SlicePostContainer>
          <Intro>
            <RichText render={slice.intro.raw}/>
          </Intro>
          <List>
            {posts.map( post =>
              <li key={post.posts.document.id}>
                <Link to={post.posts.url}>
                  <h3>{post.posts.document.data.title.text}</h3>
                  <small>{post.posts.document.data.excerpt.text}</small>
                </Link>
              </li>
            )}
          </List>
      </SlicePostContainer>
    </Holder>
  );
}

SlicePosts.propTypes = {
  slice: PropTypes.shape( {
    colour: PropTypes.oneOf( [ "Grey", "Yellow", "Purple" ] ),
    intro: PropTypes.object.isRequired,
  } ),
  posts: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

export default SlicePosts;
