import React from "react";
import PropTypes from "prop-types";
import ImageTextGrid from "../molecules/ImageTextGrid";
import GatsbyImage from "gatsby-image";
import RichText from "prismic-reactjs/src/Component";
import {Link} from "gatsby";
import styled from "styled-components";

const Text = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 0 calc( 100/12 * 1% + 1rem ) 0 0;
    &.switchOrderOnDesktop {
      padding: 0 0 0 calc( 100/12 * 1% + 2rem );
    }
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 0 calc( 100/6 * 1% + 1rem ) 0 0;
    &.switchOrderOnDesktop {
      padding: 0 0 0 calc( 100/6 * 1% + 2rem );
    }
  }
`;

const Image = styled.div`

`;

function SliceImageText({slice}) {
  return (
    <ImageTextGrid colour={slice.colour.toLowerCase()} switchOrder={slice.switch_order_on_desktop}>
      <Image>
        {slice.image.fluid && <GatsbyImage alt={slice.image.alt} fluid={slice.image.fluid} style={{maxHeight: '640px'}}/>}
      </Image>
      <Text className={ slice.switch_order_on_desktop && 'switchOrderOnDesktop' }>
        <RichText render={slice.text.raw} />
        {slice.cta.url &&
        <Link className="button" to={slice.cta.url}>Find out more</Link>}
      </Text>
    </ImageTextGrid>
  );
}

SliceImageText.propTypes = {
  slice: PropTypes.shape({
    colour: PropTypes.oneOf(["Grey", "Yellow", "Purple"]),
    cta: PropTypes.object,
    image: PropTypes.object.isRequired,
    switch_order_on_desktop: PropTypes.bool,
    text: PropTypes.object.isRequired,
  }),
};

export default SliceImageText;
