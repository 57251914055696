import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import RichText from "prismic-reactjs/src/Component";

const Holder = styled.li`
  display: block;
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.colours.yellow};
  padding: 2rem 2rem 5rem 2rem;
  border-radius: 2rem;

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }

  p {
    ${(props) => props.theme.fluidType(-2)};
  }

  li {
    ${(props) => props.theme.fluidType(-1)};
  }

  .button {
    width: 100%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-row-gap: 4rem;
  }

  h3, > p, ul {
    margin: 0;
  }

  h3, .price {
    ${(props) => props.theme.fluidType(4)};
    font-family: "GTAlpina", serif;
    line-height: 1.1;
  }
  
  > div {
    grid-column: span 2;
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-column: span 1;
    }
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function PricingCard({content}) {

  return (
    <Holder>
      <Grid>
        <h3>{content.pricing_title.text}</h3>
        <p className="price">{content.price.text}</p>
        <div>
          <p>Includes:</p>
          <RichText render={content.includes.raw} />
        </div>
        <div>
          <p>T&C's:</p>
          <RichText render={content.t_cs.raw} />
        </div>
      </Grid>
    </Holder>
  );
}

PricingCard.propTypes = {
  content: PropTypes.object.isRequired,
};

export default PricingCard;
