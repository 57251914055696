import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Container from "../atoms/Container";
import RichText from "prismic-reactjs/src/Component";
import GatsbyImage from "gatsby-image";

const Holder = styled.div`
  color: ${props => props.color === 'purple' ? props.theme.colours.white : props.theme.colours.black};
  background-color: ${props => props.theme.colours[props.color]};
  padding: 3rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 5rem 0;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 2fr 1fr;
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: 1fr 1fr;
  }
`;

const Intro = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  h2 {
    margin: 0 0 4rem 0;
  }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-column: span 2;
    grid-column-gap: 2rem;
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: repeat(3, 1fr);
  }
  li {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
  @media( ${props => props.theme.breakpoints.md} ) { 
    .img-wrapper {
      height: 350px;
    }  
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    .img-wrapper {
      height: 300px;
    }  
  }
  @media( ${props => props.theme.breakpoints.xl} ) { 
    .img-wrapper {
      height: 400px;
    }  
  }
  h3 {
    margin-top: 1.6rem;
    margin-bottom: 1.3rem;
  }
`;

function SliceCards({ partners, slice, color } ) {
  return (
    <Holder color={color.toLowerCase()}>
      <Container>
        <Inner>
          <Intro>
            <h2>{slice.title.text}</h2>
            <RichText render={slice.intro.raw}/>
          </Intro>
          <List>
            {partners.map( ( partner, i ) =>
              <li key={i}>
                <GatsbyImage alt={partner.image.alt} fluid={partner.image.fluid} className="img-wrapper"/>
                <h3 className="sans">{partner.title.text}</h3>
                <RichText render={partner.text.raw}/>
                <a href={partner.link.url} target="_blank" rel="noopener noreferrer">{partner.link_title.text}</a>
              </li>
            )}
          </List>
        </Inner>
      </Container>
    </Holder>
  );
}

SliceCards.propTypes = {
  partners: PropTypes.array.isRequired,
  slice: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'Grey',
    'Yellow',
    'Purple'
  ]),
};

SliceCards.defaultProps = {
  color: 'Grey'
};

export default SliceCards;
