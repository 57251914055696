import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Accordion from "../molecules/Accordion";
import RichText from "prismic-reactjs/src/Component";

const Holder = styled.div`
  color: ${props => props.color === 'grey' ? props.theme.colours.black : props.theme.colours.white};
  background-color: ${props => props.theme.colours[props.color]};
  padding: 3rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 5rem 0;
  }

  svg path {
    fill: ${props => props.color === 'grey' ? props.theme.colours.black : props.theme.colours.white};
  }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 3rem 0 0 0;
  width: 100%;
`;

const Inner = styled.div`

  display: ${props => props.fullWidth ? 'flex' : ''};
  flex-direction: column;
  align-items: center;
  padding: ${props => props.fullWidth ? '' : '0 2rem;'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: ${props => props.fullWidth ? '' : '0 calc( 100/12 * 1% + 2rem )'};
  }
  h2, button, p {
    width: ${props => props.fullWidth ? '100%' : ''};
    padding: ${props => props.fullWidth ? '0 2rem' : ''};
  }

`;

const SliceFaqContainer = styled.div`
  width: 100%;
`;

function SliceFAQs({faqs, color, fullWidth}) {
  return (
    <Holder color={color.toLowerCase()}>
      <SliceFaqContainer>
        <Inner fullWidth={fullWidth}>
          <h2>FAQ</h2>
          <List>
            {faqs.map((faq, i) =>
              <li key={i}>
                <Accordion title={faq.question.text}>
                  <RichText render={faq.answer.raw} />
                </Accordion>
              </li>
            )}
          </List>
        </Inner>
      </SliceFaqContainer>
    </Holder>
  );
}

SliceFAQs.propTypes = {
  faqs: PropTypes.array.isRequired,
  color: PropTypes.oneOf(['Grey', 'Yellow', 'Purple']),
  fullWidth: PropTypes.bool,
};

SliceFAQs.defaultProps = {
  color: 'yellow',
  fullWidth: false,
};

export default SliceFAQs;
