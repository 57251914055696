import React from "react";
import PropTypes from "prop-types";
import RichText from "prismic-reactjs/src/Component";
import {Link} from "gatsby";
import styled from "styled-components";
import Container from "../atoms/Container";

const Holder = styled.div`
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.colours.yellow};
  padding: 4rem 0;
  &.grey { 
    color: ${props => props.theme.colours.purple};
    background-color: ${props => props.theme.colours.grey};
  }
  &.purple { 
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.purple};
  }
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 6rem 0;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 12rem 0;
  }
`;

const Inner = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 0 calc( 100/24 * 1% + 2rem );
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    padding: 0 calc( 100/12 * 1% + 2rem );
  }
`;

const TextHolder = styled.div`
  grid-column: 2/3;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  .button {
    margin-top: 3rem;
  }
`;

function SliceSingleColumnText({slice}) {
  return (
    <Holder className={slice.colour ? slice.colour.toLowerCase() : 'grey'}>
      <Container>
        <Inner>
          <TextHolder>
            <RichText render={slice.text.raw} />
            {slice.cta_link.url &&
            <Link className="button large" to={slice.cta_link.url}>{slice.cta_text.text || 'Find out more'}</Link>}
          </TextHolder>
        </Inner>
      </Container>
    </Holder>
  );
}

SliceSingleColumnText.propTypes = {
  slice: PropTypes.shape({
    colour: PropTypes.oneOf(["Grey", "Yellow", "Purple"]),
    cta_link: PropTypes.object,
    cta_text: PropTypes.object,
    text: PropTypes.object.isRequired,
  }),
};

export default SliceSingleColumnText;
