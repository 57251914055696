import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import PropTypes from 'prop-types';

const Holder = styled.div`
  color: ${props => props.colour === 'purple' ? props.theme.colours.white : props.theme.colours.black};
  background-color: ${props => props.theme.colours[props.colour]};
  padding: 3rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 5rem 0;
  }
  &.yellow_holder + &.yellow_holder, 
  &.purple_holder + &.purple_holder {
    padding-top: 0;
  }
`;

const Inner = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  img {
    object-fit:contain !important;
  }
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    > div:first-child { order: ${props => props.switchOrder ? 2 : 'auto'}; }
    > div:last-child { order: ${props => props.switchOrder ? 1 : 'auto'}; }
  }
`;

function ImageTextGrid({ children, colour, switchOrder } ) {
  const holder_colour_class = `${colour}_holder`;
  return (
    <Holder colour={colour} className={holder_colour_class}>
      <Container>
        <Inner switchOrder={switchOrder}>{children}</Inner>
      </Container>
    </Holder>
  )
}

ImageTextGrid.propTypes = {
  colour: PropTypes.oneOf([
    'grey',
    'yellow',
    'purple'
  ]),
  switchOrder: PropTypes.bool,
};

ImageTextGrid.defaultProps = {
  colour: 'grey',
  switchOrder: false
};

export default ImageTextGrid;
