import React from 'react';
import {graphql} from 'gatsby';
import SEO from '../components/molecules/SEO';
import Hero from "../components/molecules/Hero";
import SliceImageText from "../components/organisms/SliceImageText";
import SlicePosts from "../components/organisms/SlicePosts";
import SliceFAQs from "../components/organisms/SliceFAQs";
import SliceCards from "../components/organisms/SliceCards";
import SlicePricing from "../components/organisms/SlicePricing";
import SliceSingleColumnText from "../components/organisms/SliceSingleColumnText";
import SliceTwoColumnText from "../components/organisms/SliceTwoColumnText";

function Page({data}) {
  const page = data.prismicPage.data;
  return (
    <>
      <SEO title={page.title.text}/>
      <Hero heroText={page.strapline.text} colour={page.primary_colour} layout={page.illustration_layout} desktopImage={page.illustration} mobileImage={page.mobile_illustration}/>
      {page.body.map(slice => {
        if (slice.slice_type === 'image_with_text') {
          return <SliceImageText key={slice.id} slice={slice.primary}/>;
        } else if (slice.slice_type === 'list_of_guides') {
          return <SlicePosts key={slice.id} slice={slice.primary} posts={slice.items}/>;
        } else if (slice.slice_type === 'faq') {
          return <SliceFAQs key={slice.id} color={slice.primary.colour} faqs={slice.items} fullWidth={slice.primary.full_width}/>;
        } else if (slice.slice_type === 'partner_media_grid') {
          return <SliceCards key={slice.id} color={slice.primary.colour} slice={slice.primary} partners={slice.items}/>;
        } else if (slice.slice_type === 'pricing') {
          return <SlicePricing key={slice.id} pricingPackages={slice.items}/>;
        } else if (slice.slice_type === 'single_column_text') {
          return <SliceSingleColumnText key={slice.id} slice={slice.primary}/>;
        } else if (slice.slice_type === 'two_column_text') {
          return <SliceTwoColumnText key={slice.id} slice={slice.primary}/>;
        } else {
          return null;
        }
      })}
    </>
  )
}

export default Page;

export const pageQuery = graphql`
    query($id: String!) {
        prismicPage(id: {eq: $id}) {
            data {
                title {
                    text
                }
                illustration {
                    alt
                    fluid(maxWidth: 2500) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                mobile_illustration {
                  alt
                  fluid(maxWidth: 1500) {
                      ...GatsbyPrismicImageFluid_noBase64
                  }
                }
                illustration_layout
                primary_colour
                strapline {
                    text
                }
                body {
                    ... on PrismicPageBodySingleColumnText {
                        id
                        primary {
                            colour
                            text {
                                raw
                            }
                            cta_text {
                                text
                            }
                            cta_link {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPageBodyTwoColumnText {
                        id
                        primary {
                            colour
                            column_1 {
                                raw
                            }
                            column_2 {
                                raw
                            }
                            heading {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPageBodyImageWithText {
                        id
                        primary {
                            colour
                            cta {
                                url
                            }
                            image {
                                alt
                                fluid(maxWidth: 1000) {
                                    ...GatsbyPrismicImageFluid_noBase64
                                }
                            }
                            switch_order_on_desktop
                            text {
                                raw
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPageBodyListOfGuides {
                        id
                        primary {
                            colour
                            intro {
                                raw
                            }
                        }
                        items {
                            posts {
                                url
                                document {
                                    ... on PrismicPost {
                                        id
                                        data {
                                            title {
                                                text
                                            }
                                            excerpt {
                                                text
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPageBodyFaq {
                        id
                        items {
                            answer {
                                raw
                            }
                            question {
                                text
                            }
                        }
                        primary {
                            colour
                            full_width
                        }
                        slice_type
                    }
                    ... on PrismicPageBodyPartnerMediaGrid {
                        id
                        primary {
                            colour
                            intro {
                                raw
                            }
                            title {
                                text
                            }
                        }
                        items {
                            image {
                                alt
                                fluid(maxWidth: 1000) {
                                    ...GatsbyPrismicImageFluid_noBase64
                                }
                            }
                            link_title {
                                text
                            }
                            link {
                                url
                            }
                            text {
                                raw
                            }
                            title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPageBodyPricing {
                        id
                        items {
                            price {
                                text
                            }
                            pricing_title {
                                text
                            }
                            includes {
                                raw
                            }
                            t_cs {
                                raw
                            }
                        }
                        slice_type
                    }
                }
            }
        }
    }
`;
