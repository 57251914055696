import React from "react";
import PropTypes from "prop-types";
import RichText from "prismic-reactjs/src/Component";
import styled from "styled-components";
import Container from "../atoms/Container";

const Holder = styled.div`
  color: ${props => props.colour === 'purple' ? props.theme.colours.white : props.theme.colours.black};
  background-color: ${props => props.theme.colours[props.colour]};
  padding: 3rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 5rem 0;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 0 calc(100 / 12 * 1% + 2rem);
  }
  h2 {
    margin: 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-column: span 2;
    }
  }
`;

const Column = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

function SliceTwoColumnText({slice}) {
  return (
    <Holder colour={slice.colour ? slice.colour.toLowerCase() : 'grey'}>
      <Container>
        <Inner>
          <h2>{slice.heading.text}</h2>
          <Column>
            <RichText render={slice.column_1.raw} />
          </Column>
          <Column>
            <RichText render={slice.column_2.raw} />
          </Column>
        </Inner>
      </Container>
    </Holder>
  );
}

SliceTwoColumnText.propTypes = {
  slice: PropTypes.shape({
    colour: PropTypes.oneOf(["Grey", "Yellow", "Purple"]),
    slice: PropTypes.object.isRequired,
  }),
};

export default SliceTwoColumnText;
