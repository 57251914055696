import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SliceCards from "./SliceCards";
import Container from "../atoms/Container";
import PricingCard from "../molecules/PricingCard";

const Holder = styled.div`
  padding: 3rem 0;
  background-color: ${props => props.theme.colours.purple};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 5rem 0;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
  }
`;

function SlicePricing({pricingPackages}) {

  return (
    <Holder>
      <Container>
        <Inner>
            {pricingPackages && pricingPackages.map((pricingPackage, i) =>
              <PricingCard key={i} content={pricingPackage} />,
            )}
        </Inner>
      </Container>
    </Holder>
  );
}

SliceCards.propTypes = {
  pricingPackages: PropTypes.array,
};

export default SlicePricing;
